import React, { useState } from "react";
import { CircularProgress, Paper } from "@mui/material";
import PMILogo from "../resources/images/pmi-logo.svg";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import userData from "../database/user.json";

const LoginPage = () => {
  // const { loading } = useSelector((state) => state.auth);
  // const handleLogin = () => {
  //     signIn();
  // };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Use useNavigate hook

  const handleLogin = () => {
    // Assuming you have some login API
    // Here, we just simulate a successful login
    console.log("data", userData);
    console.log("username", username);
    console.log("password", password);
    const validUser = userData.find((user) => {
      if (user.userId == username && user.password == password) {
        return user;
      }
    });

    if (validUser) {
      console.log("valid User", validUser);
      const gigyaId = validUser.gigyaId;
      // Redirect to the home page after successful login
      navigate(`/home/${gigyaId}`); // Use navigate() for redirect
    } else {
      alert("Please enter valid credentials");
    }
  };

  return (
    <div className="login-container">
      <Paper className="login-component">
        <img
          src={PMILogo}
          alt="Philip Morris International"
          width="516px"
          height="auto"
        />
        <h2 className="login-title">
          <span>Hello!</span> Welcome
        </h2>

        <input
          className="form-control mb-3"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className="form-control mb-3"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <>
          <p className="login-description">Please login to access this site</p>
          <button className="login-btn" onClick={handleLogin}>
            Log In
          </button>
        </>
      </Paper>
    </div>
  );
};

export default LoginPage;
