// AWS config

const env = process.env;

const config = {
  API: {
    endpoints: [
      {
          apiKey: env.REACT_APP_API_KEY,
          apiUrl: env.REACT_APP_API_URL
      },
    ],
  },
};

export default config;
