import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  Paper,
  Alert,
  Button,
  Chip,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import theme from "../styles";
import PMILogo from "../resources/images/pmi-logo.svg";
import config from "../config";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

function Home() {
  const [data, setData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [refresh, setRefresh] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  // const [gigyaId, setgigyaId] = useState("");
  const { gigyaId } = useParams();
  const apiKey = config.API.endpoints[0].apiKey;
  const url =
    config.API.endpoints[0].apiUrl +
    "/v1/nbaadmin/ID/nba-consumer-action/" +
    gigyaId;

  const consumerResponseUrl =
    config.API.endpoints[0].apiUrl +
    "/v1/nbaadmin/ID/nba-consumer-response/" +
    gigyaId;

  useEffect(() => {
    setPage(0);
    console.log("gigyaId", gigyaId);
    console.log("API_KEY", config.API.endpoints[0].apiUrl);
     setError(null);
    try {
      const token = apiKey;
      const correlationId = crypto.randomUUID();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Correlation-Id": correlationId,
        "x-cc-user": "Abhishek",
        "x-channel": "WEBSITE",
      };

      // Replace this URL with your actual API endpoint
      axios
        .get(url, { headers })
        .then((res) => {
          const data = res?.data;
          console.log("response", data);
          setData(data?.data?.outputRecommendations);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });

      // consumer respose api call
      axios
        .get(consumerResponseUrl, { headers })
        .then((res) => {
          const data = res?.data;
          console.log("past response", data);
          setResponseData(
            data?.data.sort(
              (a, b) => b.nbaConsumerResponseId - a.nbaConsumerResponseId
            )
          );
          // console.log("state response", responseData);
          // setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      setError("Failed to fetch data");
    }
  }, [refresh]);

  const handleResponse = async (id, status) => {
    console.log("gigyaId", gigyaId);

    setLoading(true);
    setError(null);
    try {
      const body = {
        actionType: status,
        accountId: "",
        recommendationKey: id,
        language: "EN",
        storeGuId: "",
        retailerId: "",
      };
      console.log("body", body);
      // const url = config.API.endpoints[0].apiUrl + gigyaId;
      const token = apiKey;
      const correlationId = crypto.randomUUID();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Correlation-Id": correlationId,
        "x-cc-user": "Abhishek",
        "x-channel": "WEBSITE",
      };

      // Replace this URL with your actual API endpoint
      axios
        .post(url, body, { headers })
        .then((res) => {
          const dataRes = res?.data;
          console.log("response", dataRes);
          setData(data.filter((action) => action.RecommendationKey !== id));
          setRefresh(status);
          setTimeout(() => {
            setRefresh(null);
          }, 3000);
          console.log("data", data);
        })
        .catch((error) => {
          console.log(error);
        });

      // setData(response.data);
      // setData(data.filter((action) => action.RecommendationKey !== id));
    } catch (error) {
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page is changed
  };

  return (
    <div>
      <Toolbar>
        <img src={PMILogo} alt="Philip Morris International" />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            ml: "auto",
          }}
        >
          <Link to="/help" style={{ textDecoration: "none" }}>
            <Typography
              variant="h6"
              sx={{ minWidth: 100 }}
              color={theme.palette.primary.main}
            >
              Help
            </Typography>
          </Link>
          <Tooltip title="Log Out">
            <IconButton
              size="small"
              sx={{ ml: 2 }}
              color={theme.palette.primary.main}
            >
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
      <hr />

      <h3 className="text-center">
        <u>NEXT BEST CONSUMER ACTIONS</u>
      </h3>
      {refresh && (
        <Alert severity="success">
          {refresh == "accept" ? "ACCEPTED" : "REJECTED"}
        </Alert>
      )}
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-7">
            <h5 className="text-center">CONSUMER ACTIONS</h5>
            <TableContainer style={{ marginTop: "10px" }} component={Paper}>
              <Table>
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      height: "80px",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <TableBody>
                    {data.length == 0 ? (
                      <p className="text-center">No Record Found</p>
                    ) : (
                      data.map((card) => (
                        <TableRow key={card.RecommendationKey}>
                          <TableCell className="p-14">
                            <Typography
                              variant="h6"
                              style={{ marginLeft: "10px" }}
                            >
                              {card.Name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              style={{
                                marginLeft: "10px",
                                whiteSpace: "normal", // Allows text to wrap
                                wordWrap: "break-word", // Breaks long words to avoid overflow
                                maxWidth: "500px", // Sets a max width for the description
                              }}
                            >
                              {card.Description}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box
                              display="inline-flex"
                              // flexDirection="column"
                              // justifyContent="center"
                              // alignItems="center"
                              // height="100%"
                              gap={1} // Adds spacing between buttons
                            >
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  backgroundColor: "#1565c0",
                                  color: "white",
                                  fontSize: "16px",
                                }}
                                onClick={() =>
                                  handleResponse(
                                    card.RecommendationKey,
                                    "accept"
                                  )
                                }
                                // style={{ marginRight: "8px" }}
                              >
                                <CheckIcon />
                              </Button>
                              <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={() =>
                                  handleResponse(
                                    card.RecommendationKey,
                                    "reject"
                                  )
                                }
                              >
                                <CloseIcon />
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
          <div className="col-5">
            <h5 className="text-center">CONSUMER HISTORICAL RESPONSES</h5>
            <TableContainer style={{ marginTop: "10px" }} component={Paper}>
              <Table>
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      height: "80px",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <TableBody>
                    {responseData.length == 0 ? (
                      <p className="text-center">No Record Found</p>
                    ) : (
                      responseData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((card) => (
                          <TableRow key={card.nbaConsumerResponseId}>
                            <TableCell className="p-14">
                              <Typography
                                variant="h7"
                                style={{ marginLeft: "5px" }}
                              >
                                {card.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                style={{
                                  marginLeft: "10px",
                                  whiteSpace: "normal", // Allows text to wrap
                                  wordWrap: "break-word", // Breaks long words to avoid overflow
                                  maxWidth: "800px", // Sets a max width for the description
                                }}
                              >
                                {card.description}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              {/* <Box
                              sx={{
                                p: 1,
                                backgroundColor:
                                  card.actionType === "accept"
                                    ? "#1565c0" // Blue for Accepted
                                    : card.actionType === "reject"
                                    ? "#f44336" // Red for Rejected
                                    : "rgba(255, 255, 255, 0.1)", // Default transparent
                                color: "white",
                                textAlign: "center",
                                transition: "0.2s",
                              }}
                            > */}
                              {/* <Typography
                                variant="button"
                                sx={{ fontSize: "13px", fontWeight: "bold" }}
                              >
                                {card.actionType == "accept"
                                  ? "ACCEPTED"
                                  : "REJECTED"}
                              </Typography> */}
                              <Chip
                                size="small"
                                label={
                                  card.actionType == "accept"
                                    ? "ACCEPTED"
                                    : "REJECTED"
                                }
                                color={
                                  card.actionType == "accept"
                                    ? "primary"
                                    : "error"
                                }
                              />
                              {/* </Box> */}
                            </TableCell>
                          </TableRow>
                        ))
                    )}
                    <TablePagination
                      rowsPerPageOptions={[5]} // Only option will be 5 rows per page
                      component="div"
                      count={responseData.length} // Total number of records
                      rowsPerPage={rowsPerPage} // Number of records to show per page
                      page={page} // Current page number
                      onPageChange={handleChangePage} // Handle page change
                      onRowsPerPageChange={handleChangeRowsPerPage} // Handle change in rows per page
                    />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
