import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; 
import Home from "./components/Home"; 
import LoginPage from "./components/LoginPage";
import "./index.css";
import "./styles/sass/index.scss";

function App() {
  return (
    <Router>
      <Routes>
        {" "}
        {/* Use Routes instead of Switch */}
        <Route path="/" element={<LoginPage />} />{" "}
        {/* Use 'element' instead of 'component' */}
        <Route path="/home/:gigyaId" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
